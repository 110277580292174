import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";


export default function TheStableSong() {
  return (
    <Layout>
      <Helmet title="The Stable Song | Gregory Alan Isakov" />

      <h1>The Stable Song</h1>

      <h2>Gregory Alan Isakov</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Remember when our songs were just like prayers</p>
          <p>Like gospel hymns that you called in the air</p>
          <p>Come down, come down sweet reverence</p>
          <p>Unto my simple house and ring</p>
          <p>And ring</p>
        </Verse>
        <PreChorus>
          <p>Ring like silver, ring like gold</p>
          <p>Ring out those ghosts on the Ohio</p>
          <p>Ring like clear day wedding bells</p>
          <p>Were we the belly of the beast or the sword that fell</p>
          <p>We'll never tell</p>
        </PreChorus>
        <Chorus>
          <p>Come to me clear and cold on some sea</p>
          <p>Watch the world spinning waves, like that machine</p>
        </Chorus>
        <Verse>
          <p>Now I've been crazy couldn't you tell</p>
          <p>I threw stones at the stars, but the whole sky fell</p>
          <p>Now I'm covered up in straw, belly up on the table</p>
          <p>Well I drank and sang, and passed in the stable</p>
        </Verse>
        <Verse>
          <p>That tall grass grows high and brown</p>
          <p>Well I dragged you straight in the muddy ground</p>
          <p>And you sent me back to where I roam</p>
          <p>Well I cursed and I cried, but now I know</p>
          <p>Now I know</p>
        </Verse>
        <Verse>
          <p>And I ran back to that hollow again</p>
          <p>The moon was just a sliver back then</p>
          <p>And I ached for my heart like some tin man</p>
          <p>When it came oh it beat and it boiled and it rang</p>
          <p>Oh it's ringing</p>
        </Verse>
        <PreChorus>
          <p>Ring like crazy, ring like hell</p>
          <p>Turn me back into that wild haired gale</p>
          <p>Ring like silver, ring like gold</p>
          <p>Turn these diamonds straight back into coal</p>
        </PreChorus>
      </Columns>
    </Layout>
  );
}
